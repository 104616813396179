import { LazyLoading } from '@/_components/Loading';
import Loadable from 'react-loadable';

const SalesOrderDetails = Loadable({
  loader: () => import('./components/SalesOrderDetails'),
  loading: LazyLoading,
});

const SalesOrderList = Loadable({
  loader: () => import('./components/SalesOrderList'),
  loading: LazyLoading,
});

const OrderDetails = Loadable({
  loader: () => import('./components/OrderDetails'),
  loading: LazyLoading,
});

const SalesOrderFulfillment = Loadable({
  loader: () => import('./components/OrderFulfillment'),
  loading: LazyLoading,
});

const ShipmentInformationDetails = Loadable({
  loader: () => import('./components/ShipmentInformationDetails'),
  loading: LazyLoading,
});

export const salesRoutes = [
  // TODO: This will be deactivated once we switch the table layout
  {
    path: '/orders',
    component: SalesOrderList,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/:id',
    component: OrderDetails,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/sales-order/:id/details',
    component: SalesOrderDetails,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/sales-order/:id/fulfill',
    component: SalesOrderFulfillment,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
  {
    path: '/orders/sales-order/:id/shipment-information',
    component: ShipmentInformationDetails,
    exact: true,
    props: {
      loggedInOnly: true,
    },
  },
];
